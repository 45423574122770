<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Agregar una nueva etapa</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pb-0">
                <form-group
                  name="nombre de la sección"
                  :validator="$v.stage.name"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Etapa"
                    placeholder="Ingresa el nombre de la etapa"
                    required
                    outlined
                    v-model="stage.name"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" sm="12" class="pb-0">
                <form-group
                  name="tipo de etapa"
                  :validator="$v.stage.stage_type_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Tipo de etapa"
                    placeholder="Selecciona un tipo de etapa"
                    :items="stageTypes"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    v-model="stage.stage_type_id"
                  ></v-select>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" sm="12" class="pb-0">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-model="start"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <form-group
                      name="fecha de inicio"
                      :validator="$v.stage.start"
                      v-bind="attrs"
                    >
                      <v-text-field
                        v-model="stage.start"
                        label="Seleccione la fecha de inicio"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        required
                        outlined
                        v-bind="attrs"
                        slot-scope="{ attrs }"
                        v-on="on"
                      ></v-text-field>
                    </form-group>
                  </template>
                  <v-date-picker
                    v-model="stage.start"
                    :min="
                      new Date(new Date().getFullYear(), 0, 1)
                        .toISOString()
                        .slice(0, 10)
                    "
                    :max="stage.end"
                    @input="start = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" sm="12" class="pb-0">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-model="end"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <form-group
                      name="fecha de inicio"
                      :validator="$v.stage.end"
                      v-bind="attrs"
                    >
                      <v-text-field
                        v-model="stage.end"
                        label="Selecciona la fecha de finalización"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        required
                        outlined
                        v-bind="attrs"
                        slot-scope="{ attrs }"
                        v-on="on"
                      ></v-text-field>
                    </form-group>
                  </template>
                  <v-date-picker
                    v-model="stage.end"
                    :min="stage.start"
                    :max="
                      new Date(new Date().getFullYear(), 11, 31)
                        .toISOString()
                        .slice(0, 10)
                    "
                    @input="end = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn color="primary" depressed :loading="isLoading" @click="submit"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import stageRepository from "@/repositories/stageRepository";

export default {
  name: "CreateStageModal",

  data() {
    return {
      start: false,
      end: false,
      isLoading: false,
      dialog: false,
      stage: {},
    };
  },
  props: {
    stageTypes: {
      type: Array,
    },
  },
  methods: {
    create() {
      this.isLoading = true;
      stageRepository
        .createStage(this.stage)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar el registro 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.create();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.stage = {};
    },
  },
  validations: {
    stage: {
      name: {
        required,
        maxLength: maxLength(255),
      },
      stage_type_id: {
        required,
      },
      start: {
        required,
      },
      end: {
        required,
      },
    },
  },
};
</script>

<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Gestión de las etapas
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar, crear, actualizar, eliminar y cambiar
              el estado de las etapas.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="showMdlCreate"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg>
            </span>
            Crear etapa
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-9 col-xl-8">
              <div class="row align-items-center">
                <div class="col-md-4 my-2 my-md-0">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="stages.search"
                  ></v-text-field>
                </div>
                <div class="col-md-4 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      :label="
                        stageTypes.isLoading
                          ? 'Cargando tipos de etapas'
                          : 'Etapa'
                      "
                      :loading="stageTypes.isLoading"
                      :disabled="!stageTypes.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="stageTypes.data"
                      item-text="name"
                      item-value="id"
                      v-model="stages.filters.stage_type_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ stages.filters.stage_type_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="stages.headers"
            :items="filteredData"
            :search="stages.search"
            :items-per-page="10"
            :loading="stages.isLoading"
          >
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="existInArray('Editar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        selectStage(item);
                        showMdlUpdate();
                      "
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-if="existInArray('Eliminar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectStage(item);
                        showMdlDelete();
                      "
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <CreateStageModal
      ref="mdlCreate"
      :stageTypes="stageTypes.data"
      @reload-data="loadStages"
    ></CreateStageModal>

    <UpdateStageModal
      ref="mdlUpdate"
      @reload-data="loadStages"
      @clean-selection="unselectStage"
      :stageTypes="stageTypes.data"
      :stage="selectedStage"
    ></UpdateStageModal>

    <DeleteBaseModal ref="mdlDelete" @delete="deleteStage">
      <template v-slot:title>¿Desea eliminar este etapa?</template>
      <template v-slot:subject>
        la etapa
        <span class="font-weight-bolder">{{ selectedStage.name }}</span>
      </template>
    </DeleteBaseModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import stageTypeRepository from "@/repositories/stageTypeRepository";
import stageRepository from "@/repositories/stageRepository";
import CreateStageModal from "@/components/modals/stages/CreateStageModal.vue";
import UpdateStageModal from "@/components/modals/stages/UpdateStageModal.vue";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "Stages",
  title: "Etapas | GE ITR",
  components: {
    CreateStageModal,
    UpdateStageModal,
    DeleteBaseModal,
  },
  data() {
    return {
      stages: {
        headers: [
          { text: "Etapa", value: "name" },
          { text: "Fecha inicio", value: "start" },
          { text: "Fecha finalización", value: "end" },
          { text: "Tipo de etapa", value: "stage_type.name" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      selectedStage: {},
      stageTypes: { data: [], isLoading: false },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Etapas", route: "stages" },
    ]);
    this.loadStages();
    this.getStageTypes();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Etapas");
  },
  methods: {
    selectStage(obj) {
      // Creating a non reactive copie of data
      this.selectedStage = { ...obj };
    },
    unselectStage() {
      this.selectedStage = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    showMdlChangeStatus() {
      this.$refs.mdlChangeStatus.toggleModal();
    },
    loadStages() {
      this.stages.isLoading = true;
      stageRepository
        .getAllStages()
        .then((response) => {
          this.stages.data = response.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.stages.isLoading = false;
        });
    },
    deleteStage() {
      stageRepository
        .deleteStage(this.selectedStage.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.loadStages();
          }
        })
        .catch(() => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible eliminar el registro seleccionado en estos momentos 🙁`,
          });
        });
    },
    getStageTypes() {
      this.stageTypes.isLoading = true;
      stageTypeRepository
        .getAllStageTypes()
        .then(({ data }) => {
          this.stageTypes.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.stageTypes.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    filteredData() {
      return this.filterData(this.stages.filters, this.stages.data);
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
